/* eslint-disable */
import {
  Typography,
  RACRadio,
  Grid,
  List,
  ListItem,
  Card,
  CardContent,
  RACTextbox,
  RACTable,
  RACDatePicker,
  RACTableCell,
  RACSelect,
  RACTableRow,

} from '@rentacenter/racstrap';
import { ReactComponent as SortUpIcon } from "../../../assets/images/sort-up-icon.svg";
import { ReactComponent as SortDownIcon } from "../../../assets/images/sort-down-icon.svg";
import { ReactComponent as AddRow } from "../../../assets/images/action-add-row.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/Edit Icon.svg";
import { ReactComponent as DeleteDanger } from "../../../assets/images/delete-danger.svg";
import { ReactComponent as Danger } from "../../../assets/images/danger.svg";
import { ReactComponent as EditCheck } from "../../../assets/images/edit-update-check.svg";
import { ReactComponent as EditCross } from "../../../assets/images/edit-notupdate-cross.svg";
import { MultiSelect } from 'react-multi-select-component';
import { globalStyles } from '../Styles/globalstyles';
import { SECONDARY_CRITERIA } from '../../../constants/constants';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { CriteriaContext } from './CriteriaContext';

import { PricingReportStyle } from '../Styles/PricingReport';
import { getBusinessValueIcon } from '../../shared/BusinessValueIcon';
import { SecondaryCriteriaStyle } from '../Styles/SecondaryCriteria';
export default function SecondaryCriteriaForm() {
  const Classes = globalStyles();
  const pricing = PricingReportStyle();
  const sc = SecondaryCriteriaStyle();

  const {
    showComp,
    loader,
    dropDownSetter,
    dropdownValues,
    criteriaDetailsForm,
    setCriteriaDetailsForm,
    selectQueueType,
    setSelectQueueType,
    editCriteria,
    handleCancelClick,
    createDiscountIntervals,
    editRecordsArray, setEditRecordsArray, setHandlePopup,
    zoneSelectedForm, setZoneSelectedForm,
    backupRecords, setBackupRecords
  } = useContext(CriteriaContext);

  const [subDeptOptions, setSubDeptOptions] = useState<any>([
    {
      label: 'Select',
      value: '',
      departmentNumber: '',
    },
  ]);
  const [bracketOptions, setBracketOptions] = useState<any>([
    {
      label: 'Select',
      value: '',
      subdepartmentNumber: '',
    },
  ]);
  const [addnewRange, setAddNewRange] = useState({ startRange: "", endRange: "", discountValue: "" });

  const [catSubType, setCatSubType] = useState([]);
  const [equationParam, setEquationParam] = useState([]);
  const criteriaEffectiveType = [
    {
      label: 'Select',
      value: '',
    },
    {
      label: 'Number',
      value: 'NUMBER',
    },
    {
      label: 'Percentage',
      value: 'PERCENTAGE',
    },
  ];
  let rangeHeaders = [
    {
      heading: "Start Range",
      isSortable: true,
      sortDirection: "DESC",
      key: "startRange",
      isSorted: true,
    },
    {
      heading: "End Range",
      isSortable: true,
      sortDirection: "DESC",
      key: "endRange",
      isSorted: true,
    },
    {
      heading: "Discount",
      isSortable: true,
      sortDirection: "DESC",
      key: "discountValue",
      isSorted: true,
    },
    {
      heading: "Action",
      isSortable: false,
      sortDirection: "DESC",
      key: "action",
      isSorted: true,
    },
  ];
  const [rangeHeader, setRangeHeader] = useState<any>(rangeHeaders);
  const [criteriaType, setCriteriaType] = useState([]);

  useEffect(() => {
    setCatSubType(
      dropDownSetter(dropdownValues.categorySubType, 'categorySubType')
    );
    filterSecondaryCriteria()
  }, [showComp.form, dropdownValues.categorySubType]);

  useEffect(() => {
    if (editCriteria) {
      const { zone, departmentNumber, subdepartmentNumber, equation, ruleType } = criteriaDetailsForm;
      const zoneItem = dropdownValues.zone?.filter((item: any) => {
        return item.value == zone[0]
      }).filter(Boolean)
      setZoneSelectedForm(zoneItem)
      if (departmentNumber) {
        filterSubDept(departmentNumber)
      }
      if (subdepartmentNumber) {
        filterBracket(subdepartmentNumber)
      }
      if (ruleType == 'EQUATION' && equation.equationType) {
        filterEquation(equation.equationType)
      }

    }

  }, [editCriteria]);

  const filterSubDept = (e: any) => {
    const dataArray: any = [];
    const subDept = dropdownValues.subdepartment?.filter(
      (obj: any) => {
        if (obj.departmentNumber == e) {
          return obj;
        }
      }
    );
    if (subDept.length > 0) {
      subDept.map((item: any) => {
        dataArray.push({
          label: item?.descEn,
          value: item?.subdepartmentNumber?.toString(),
          departmentNumber: item.departmentNumber,
        });
      });
      dataArray.sort((a: any, b: any) =>
        a.label?.localeCompare(b.label)
      );
      dataArray.unshift({
        label: 'Select',
        value: '',
        departmentNumber: '',
      });
      setSubDeptOptions(dataArray);
    }
  }
  const filterBracket = (e: any) => {
    const dataArray: any = [];
    const bracket = dropdownValues.bracket?.filter((obj: any) => {
      if (obj.subdepartmentNumber == e) {
        return obj;
      }
    });
    if (bracket.length > 0) {
      bracket.map((item: any) => {
        dataArray.push({
          label: item?.descEn,
          value: item?.bracketNumber?.toString(),
          subdepartmentNumber: item.subdepartmentNumber,
        });
      });
      dataArray.sort((a: any, b: any) =>
        a.label?.localeCompare(b.label)
      );
      dataArray.unshift({
        label: 'Select',
        value: '',
        subdepartmentNumber: '',
      });
      setBracketOptions(dataArray);
    }
  }
  const filterEquation = (e: any) => {
    const equParam = dropdownValues.equationType
      .filter((obj: any) => {
        return obj.refCode == e;
      })
    if (equParam.length > 0) {
      const equationParam = equParam[0]?.params?.map((obj: any) => {
        return {
          parameterName: obj.parameterName,
          equationParamKey: obj.refCode,
          discountValue: ''
        };
      });
      if (editCriteria) {
        criteriaDetailsForm.equation.parameters

        // Mapping discount values from edit to equationParam
        const updatedEquationParam = equationParam?.map((param: any) => {
          // Find the corresponding item in edit using refCode
          const matchingEdit = criteriaDetailsForm.equation.parameters.find((e: any) => e.equationParamKey === param.equationParamKey);

          // If a match is found, update the discountValue; otherwise, keep it as is
          return {
            ...param,
            discountValue: matchingEdit ? matchingEdit.discountValue : param.discountValue
          };
        });
        setEquationParam(updatedEquationParam ?? []);
        const obj = {
          equationType: e,
          parameters: updatedEquationParam ?? null
        }
        setCriteriaDetailsForm({ ...criteriaDetailsForm, equation: obj })
      }
      else {

        setEquationParam(equationParam ?? []);
        const obj = {
          equationType: e,
          parameters: equationParam ?? null
        }
        console.log('The equation param', obj)
        setCriteriaDetailsForm({ ...criteriaDetailsForm, equation: obj })
      }
    }
  }
  const sortArray = (
    columnId: string,
    index: any,
    datacolumn: any,
    dataValues: any,
    compareBy: string = ""
  ) => {
    try {
      debugger;
      let sortedData;
      if (compareBy == "date") {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) => {
              let date1 = moment(b[columnId]);
              let date2 = moment(a[columnId]);
              return date2.isBefore(date1) ? -1 : 1;
            })
            : dataValues.sort((a: any, b: any) => {
              let date1 = moment(b[columnId]);
              let date2 = moment(a[columnId]);
              return date1.isBefore(date2) ? -1 : 1;
            });
      } else if (compareBy == "number") {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) => b[columnId] - a[columnId])
            : dataValues.sort((a: any, b: any) => a[columnId] - b[columnId]);
      } else {
        sortedData =
          datacolumn[index].sortDirection == "DESC"
            ? dataValues.sort((a: any, b: any) =>
              b[columnId] < a[columnId] ? -1 : 1
            )
            : dataValues.sort((a: any, b: any) =>
              a[columnId] < b[columnId] ? -1 : 1
            );
      }
      const reorderColumns = datacolumn;
      reorderColumns[index].sortDirection =
        reorderColumns[index].sortDirection == "ASC" ? "DESC" : "ASC";
      reorderColumns.map((value: any, index1: any) => {
        reorderColumns[index1].IsSorted = index == index1 ? true : true;
      });
      return [[...sortedData], [...reorderColumns]];
    } catch (error) {
      console.error(error);
      return [undefined, undefined]

    }
  };
  function filterSecondaryCriteria(type: string = '') {
    const secondaryCriteriaType = dropDownSetter(
      dropdownValues.secondaryCriteria,
      'secondaryCriteriaType'
    )
    if (type == 'EQUATION' || type == 'TABLE') {
      const filterSC = secondaryCriteriaType.filter((item: any) => item.value != 'CATEGORY');
      setCriteriaType(filterSC)
    }
    else {
      setCriteriaType(secondaryCriteriaType)
    }
  }

  const ruleType = async (e: any) => {
    if (e.target.value == 'CATEGORY') {
      setCriteriaDetailsForm({
        ...criteriaDetailsForm,
        ['ruleType']: e.target.value,
        ['secondaryCriteriaType']: 'CATEGORY',
        ['range']: null,
        equation: null,
        ['category']: {
          categoryType: 'DEPRECTYP',
          categorySubType: '',
        },
      });
      filterSecondaryCriteria('CATEGORY')
    } else if (e.target.value == 'EQUATION') {
      setCriteriaDetailsForm({
        ...criteriaDetailsForm,
        ['ruleType']: e.target.value,
        ['secondaryCriteriaType']: '',
        ['category']: null,
        ['range']: null,
        ['equation']: {
          equationType: '',
        },
      });
      filterSecondaryCriteria(e.target.value)
    } else {
      const tableRange = await createDiscountIntervals()
      setCriteriaDetailsForm({
        ...criteriaDetailsForm,
        ['ruleType']: e.target.value,
        ['secondaryCriteriaType']: '',
        equation: null,
        ['category']: null,
        ['range']: tableRange,
      });
      filterSecondaryCriteria(e.target.value)
    }
  };
  const secondaryCriteriaInput = (e: any, paramType: string) =>{

    if(criteriaDetailsForm?.ruleType =='CATEGORY'){
     const obj = criteriaDetailsForm.category;
     obj.discountValue =''
     setCriteriaDetailsForm({
      ...criteriaDetailsForm,
      [paramType]: e.target.value,
      category : obj

    })

    }else if(criteriaDetailsForm?.ruleType =='TABLE'){
      const range = criteriaDetailsForm.range;
      if(range.length >0){
       range.forEach((item: any)=> item.discountValue = '') 
      }
      setCriteriaDetailsForm({
        ...criteriaDetailsForm,
        [paramType]: e.target.value,
        range : range
      })

    }else if(criteriaDetailsForm?.ruleType == 'EQUATION'){
      const obj = criteriaDetailsForm.equation;
      if(obj?.parameters?.length > 0){
        obj.parameters.forEach((item:any)=>{
          item.discountValue ='';
        })
        setEquationParam(obj.parameters);
        console.log('The equation param',obj?.equation )
        setCriteriaDetailsForm({
          ...criteriaDetailsForm,
          [paramType]: e.target.value,
          equation : obj
        })
      }else{
        setCriteriaDetailsForm({
          ...criteriaDetailsForm,
          [paramType]: e.target.value,
        })
      }

    }else{
      setCriteriaDetailsForm({
        ...criteriaDetailsForm,
        [paramType]: e.target.value,
      })
    }
    
  }
  const handleAddRow = () => {
    debugger;
    const newRow = { startRange: "", endRange: "", discountValue: "" };
    let arr = JSON.parse(JSON.stringify(criteriaDetailsForm.range));
    arr.unshift((JSON.stringify(addnewRange) == JSON.stringify(newRow)) ? newRow : JSON.parse(JSON.stringify(addnewRange)));
    setCriteriaDetailsForm({ ...criteriaDetailsForm, ["range"]: JSON.parse(JSON.stringify(arr)) });
    setBackupRecords({ ...backupRecords, ["range"]: JSON.parse(JSON.stringify(arr)) });
    setAddNewRange(newRow);

    let editArr = editRecordsArray;
    editArr.forEach((item: any, index: any) => {

      editArr[index] = editArr[index] + 1;
    });
    if (!(addnewRange.startRange && addnewRange.endRange && addnewRange.discountValue)) {
      editArr.push(0);
    }
    setEditRecordsArray([...editArr]);
  };
  const handleRangeInputs = (
    key: string,
    currentEvent: any,
    rangeObject: any,
    index: number
  ) => {
    // debugger;
    const updatedFormRecords = { ...criteriaDetailsForm };

    // Create a copy of the range array
    const updatedRangeArray = [...updatedFormRecords.range];

    // Assuming you have the updated values
    const updatedValues: any = rangeObject;
    if (key.toLowerCase() == "discountValue") {
        currentEvent = rangeObject.discountValue.replace('%', '')
    } else if (
      key.toLowerCase() == "startRange" ||
      key.toLowerCase() == "endRange"
    ) {
      currentEvent = currentEvent.replace(/\D/g, rangeObject[key]);
    }

    // Update the object at the specified index with the new values
    updatedValues[key] = currentEvent;
    updatedRangeArray[index] = updatedValues;
    if (!(editCriteria) && updatedValues.startRange && updatedValues.endRange && updatedValues.discountValue) {
      editRecordsArray.splice(editRecordsArray.indexOf(0), 1)
    }

    // Update the formRecords with the updated range array
    updatedFormRecords.range = updatedRangeArray;
    console.log(updatedFormRecords, "The updated form records", criteriaDetailsForm);
    setCriteriaDetailsForm({ ...updatedFormRecords });
  };

  const handleSaveRow = (rowIndex: any) => {
    debugger;
    const updatedRow = JSON.parse(JSON.stringify(criteriaDetailsForm["range"][rowIndex]));
    setBackupRecords(() => {
      const newBackupRecords = backupRecords;
      newBackupRecords["range"][rowIndex] = updatedRow;
      return newBackupRecords;
    });
    setEditRecordsArray(() =>
      editRecordsArray.filter((index: any) => index !== rowIndex)
    );
  };
  const handleCancelEdit = (rowIndex: any) => {
    debugger;
    const originalRow = JSON.parse(JSON.stringify(backupRecords["range"][rowIndex]));

    setCriteriaDetailsForm(() => {
      const newFormRecords = criteriaDetailsForm;
      newFormRecords["range"][rowIndex] = originalRow;
      return newFormRecords;
    });

    setEditRecordsArray(() =>
      editRecordsArray.filter((index: any) => index !== rowIndex)
    );
  };
  // Function to handle editing a row
  const handleEditRow = (rowIndex: any) => {
    debugger;
    console.log(editRecordsArray, "the records to edit");

    setEditRecordsArray([...editRecordsArray, rowIndex]);
  };


  function breadcrumb() {
    return (
      <List className={`${Classes.breadcrumb} ${Classes.py1}`}>
        <ListItem
          style={{ paddingLeft: '0' }}
          className={`${Classes.breadcrumbItemActive} `}
        >
          <a style={{ cursor: 'pointer' }} onClick={handleCancelClick}>
            Secondary Criteria Details
          </a>
        </ListItem>
        <ListItem
          className={`${Classes.breadcrumbItemActive1}  ${Classes.breadcrumbItem} ${Classes.breadcrumbArrow} `}
        >
          <a>{`${(!(editCriteria) ? 'New ' : 'Update ') + SECONDARY_CRITERIA}`}</a>
        </ListItem>
      </List>
    );
  }

  function SecondaryCriteria() {
    const startDate = criteriaDetailsForm.startDate && moment(criteriaDetailsForm.startDate, "YYYY-MM-DD");
    const currentDate = moment(moment(new Date()).format("YYYY-MM-DD"), "YYYY-MM-DD");
    const endDate = criteriaDetailsForm.endDate && moment(criteriaDetailsForm.endDate, "YYYY-MM-DD");
    return (
      <Grid item xs={12} md={12} lg={6} >
        <Typography variant="h4">
          {' '}
          {!(editCriteria) ? 'New Secondary Criteria' : 'Update Secondary Criteria'}
        </Typography>
        <Card className={`${Classes.p3} `} style={{ height: '70vh' }}>
          <CardContent>
            <Grid container spacing={2} style={{ overflowY: 'auto', height: "68vh" }}>
              {/* Zone */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <Typography>
                  Zone<span style={{ color: 'red' }}>*</span>
                </Typography>
                <MultiSelect
                  isLoading={loader.dropDownLoader}
                  disabled={editCriteria}
                  options={dropDownSetter(dropdownValues.zone, 'zone')}
                  value={zoneSelectedForm}
                  className={`${pricing.demo}  ${pricing.prcingzoneMultiSelect} `}
                  hasSelectAll={true}
                  onChange={(e: any) => {
                    setZoneSelectedForm(e);
                    let arr = e.map((item: any) => {
                      if (item.value == item.value) return item.value;
                    });
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['zone']: arr,
                    });
                  }}
                  labelledBy={'Select'}
                />
              </Grid>
              {/* department */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <RACSelect
                  isDisabled={editCriteria}
                  inputLabel="Department"
                  options={dropDownSetter(
                    dropdownValues.department,
                    'department'
                  )}
                  required
                  loading={loader.dropDownLoader}
                  defaultValue={criteriaDetailsForm.departmentNumber}
                  onChange={(e) => {
                    debugger;
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['departmentNumber']: e.target.value,
                      ['subdepartmentNumber']: '',
                      ['bracketNumber']: '',
                      ['rmsItemNumber']: ''
                    });
                    filterSubDept(e.target.value)

                  }}
                ></RACSelect>
              </Grid>
              {/* sub department */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <RACSelect
                  isDisabled={
                    criteriaDetailsForm.departmentNumber && !editCriteria
                      ? false
                      : true
                  }
                  inputLabel="Sub Department"
                  options={subDeptOptions}
                  loading={loader.dropDownLoader}
                  defaultValue={criteriaDetailsForm.subdepartmentNumber}
                  onChange={(e) => {
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['subdepartmentNumber']: e.target.value,
                      ['bracketNumber']: '',
                      ['rmsItemNumber']: ''
                    });
                    filterBracket(e.target.value);
                  }}
                ></RACSelect>
              </Grid>

              <Grid item xs={4} className={`${Classes.mt3} `}>
                <RACSelect
                  inputLabel="Bracket"
                  options={bracketOptions}
                  defaultValue={criteriaDetailsForm.bracketNumber}
                  isDisabled={
                    criteriaDetailsForm.subdepartmentNumber && !editCriteria
                      ? false
                      : true
                  }
                  loading={loader.dropDownLoader}
                  onChange={(e) =>
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['bracketNumber']: e.target.value,
                      ['rmsItemNumber']: ''
                    })
                  }
                ></RACSelect>
              </Grid>
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <Typography>Brand</Typography>
                <RACTextbox
                  disabled={editCriteria}
                  value={criteriaDetailsForm.brandName}
                  maxlength={50}
                  OnChange={(e) =>
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['brandName']: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <Typography>RMS #</Typography>
                <RACTextbox
                  type={'number'}
                  disabled={
                    criteriaDetailsForm.bracketNumber && !editCriteria
                      ? false
                      : true
                  }
                  value={criteriaDetailsForm.rmsItemNumber}
                  OnChange={(e) =>
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['rmsItemNumber']: e.target.value,
                    })
                  }
                />
              </Grid>
              {/* Rule Type */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <RACSelect
                  inputLabel="Rule Type"
                  required={true}
                  isDisabled={editCriteria}
                  options={dropDownSetter(
                    dropdownValues.ruleType,
                    'ruleType',
                    false
                  )}
                  defaultValue={criteriaDetailsForm.ruleType}
                  loading={loader.dropDownLoader}
                  onChange={ruleType}
                ></RACSelect>
              </Grid>
              {/* Rule Application Order */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <Typography>
                  Rule Application Order<span style={{ color: 'red' }}>*</span>
                </Typography>
                <RACTextbox
                  disabled={false}
                  type={'number'}
                  value={criteriaDetailsForm.applicationOrder}
                  OnChange={(e) => {
                    if (e.target.value === '') {
                      setCriteriaDetailsForm({
                        ...criteriaDetailsForm,
                        applicationOrder: '',
                      });
                      return;
                    }

                    // Regex to check for valid number input (1-9999)
                    const numberRegex = /^[1-9]\d{0,3}$/;

                    if (numberRegex.test(e.target.value)) {
                      setCriteriaDetailsForm({
                        ...criteriaDetailsForm,
                        applicationOrder: e.target.value,
                      });
                    }
                  }}
                />
              </Grid>
              {/* Secondary Criteria Type */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <RACSelect
                  inputLabel="Secondary Criteria Type"
                  required={true}
                  options={criteriaType}
                  isDisabled={
                    criteriaDetailsForm.ruleType == 'CATEGORY' ||
                    editCriteria
                  }
                  loading={loader.dropDownLoader}
                  defaultValue={criteriaDetailsForm.secondaryCriteriaType}
                  onChange={(e : any)=>secondaryCriteriaInput(e, 'secondaryCriteriaType')}
                ></RACSelect>
              </Grid>

              {/* Price Parameter */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <RACSelect
                  inputLabel="Price Parameter"
                  required={true}
                  options={dropDownSetter(
                    dropdownValues.pricingParams,
                    'priceParameter', true, true
                  )}
                  isDisabled={editCriteria}
                  loading={loader.dropDownLoader}
                  defaultValue={criteriaDetailsForm.pricingParameterKey}
                  onChange={(e :any)=>secondaryCriteriaInput(e,'pricingParameterKey')}
                ></RACSelect>
              </Grid>
              {/* Criteria Effect Type */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <RACSelect
                  inputLabel="Criteria Effect Type"
                  required={true}
                  options={criteriaEffectiveType}
                  isDisabled={false}
                  defaultValue={criteriaDetailsForm.effectType}
                  onChange={(e :any)=>secondaryCriteriaInput(e,'effectType')
                  }
                ></RACSelect>
              </Grid>

              {/* Start Date */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                <RACDatePicker
                  required
                  label="Start Date"
                  value={criteriaDetailsForm.startDate}
                  inputProps={{
                    min: moment().format('YYYY-MM-DD'),
                  }}
                  onChange={(e) =>
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['startDate']: e,
                    })
                  }
                />
              </Grid>
              {startDate && (startDate?.isBefore(currentDate)) ? <Grid item md={12} className={`${Classes.mt1}`} style={{ display: 'inline', float: 'right', marginRight:'1%' }}>
                <Typography style={{
                  color: 'red', fontSize: '0.70rem', display: 'flex',
                  flexDirection: 'row-reverse'
                }}>Start Date should not be a past date.</Typography>
              </Grid> : null}

              {/* End Date */}
              <Grid item xs={4} className={`${Classes.mt3} ${Classes.mb3} `}>
                <RACDatePicker
                  disabled={!editCriteria}
                  label="End Date"
                  value={criteriaDetailsForm.endDate}
                  required={editCriteria}
                  inputProps={{
                    min: moment().format('YYYY-MM-DD'),
                  }}
                  onChange={(e) =>
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['endDate']: e,
                    })
                  }
                />
              </Grid>
              {endDate && (endDate?.isBefore(currentDate)) ? <Grid item md={12} className={`${Classes.mt1}`} style={{ padding: '0px 16px' }}>
                <Typography style={{
                  color: 'red', fontSize: '0.70rem'
                }}>End Date should not be a past date.</Typography>
              </Grid> : (startDate && endDate && endDate?.isBefore(startDate)) ? <Grid item md={12} style={{ padding: '0px 16px' }}>
                <Typography style={{ color: 'red', fontSize: '0.70rem', }}>End Date should be greater than or equal to start Date.</Typography>
              </Grid> : null}
              <Grid
                spacing={3}
                item
                xs={12}
                md={12}
                style={{ marginTop: '0.5%', marginLeft: '2% !important' }}
              >
                <Typography>
                  {' '}
                  How do you want to update the secondary criteria?
                  <span style={{ color: 'red' }}>*</span>{' '}
                </Typography>
                <Grid
                  style={{
                    margin: '1%',
                  }}
                >
                  <RACRadio
                    value={''}
                    checked={selectQueueType == 'IMMEDIATE'}
                    label="Immediately"
                    name="updateType"
                    onClick={() => setSelectQueueType('IMMEDIATE')}
                  ></RACRadio>
                  <RACRadio
                    value={''}
                    checked={selectQueueType == 'OVERNIGHT'}
                    label="Overnight"
                    name="updateType"
                    onClick={() => setSelectQueueType('OVERNIGHT')}
                  ></RACRadio>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  const RangeHead = () => (
    <>
      {rangeHeader.map((value: any, index: any) => {
        return (
          <>
            <RACTableCell>
              {value.isSortable ? (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  id={value.columnId}
                  onClick={() => {
                    if (editRecordsArray.length == 0) {
                      let rep = sortArray(
                        value.key,
                        index,
                        rangeHeader,
                        criteriaDetailsForm.range,
                        "number"
                      );
                      console.log(rep, "the sort array in the form");

                      setCriteriaDetailsForm({ ...criteriaDetailsForm, range: rep[0] });
                      setRangeHeader(rep[1]);
                    }

                  }}
                >
                  {value.heading + "  "}
                  {value.isSorted === true ? (
                    value.sortDirection == "DESC" ? (
                      <SortUpIcon
                        style={{ cursor: "pointer" }}
                        className={Classes.ml0}
                      />
                    ) : (
                      <SortDownIcon
                        style={{ cursor: "pointer" }}
                        className={Classes.ml0}
                      />
                    )
                  ) : null}
                </a>
              ) : (
                <a
                  style={{
                    color: "black",
                    textDecoration: "none",
                  }}
                  id={value.columnId}
                >
                  {value.heading + "  "}
                </a>
              )}
            </RACTableCell>
          </>
        );
      })}
    </>
  );
  const tableSymbol = (value: string) => {
    const type = criteriaDetailsForm.effectType
    const variant = criteriaDetailsForm.pricingParameterKey
    if (type == 'NUMBER') {
      if (['EPOPERCENT', 'WKTERM', 'SACDAYS'].includes(variant))
        return value
      else
        return `$${value}`
    }
    else {
      return `${value}%`
    }
  }

  const RangeContent = () => {
    return (
      <>
        <RACTableRow style={{ backgroundColor: "transparent" }}>
          <RACTableCell>
            <RACTextbox value={addnewRange.startRange} OnChange={(e: any) => {
              e.target.value = e.target.value.replace(/\D/g, "");
              setAddNewRange({ ...addnewRange, ['startRange']: e.target.value })
            }} />
          </RACTableCell>
          <RACTableCell>
            <RACTextbox value={addnewRange.endRange} OnChange={(e: any) => {
              e.target.value = e.target.value.replace(/\D/g, "");
              setAddNewRange({ ...addnewRange, ['endRange']: e.target.value })
            }} />
          </RACTableCell>
          <RACTableCell>
            {Inputfield({fieldName:'', iconRequired : true, isEquation : false}, addnewRange.discountValue, (e: any) => {
              const numberPattern = /^(100(\.0+)?|\d{1,2}(\.\d{0,2})?)$/;
              if (!numberPattern.test(e.target.value)) {
                e.target.value = ''
              }
              setAddNewRange({ ...addnewRange, ['discountValue']: e.target.value })
            })}
            {/*  <RACTextbox value={addnewRange.discountValue} OnChange={(e: any) => {
              const numberPattern = /^(100(\.0+)?|\d{1,2}(\.\d{0,2})?)$/;
              if (!numberPattern.test(e.target.value)) {
                e.target.value = ''
              }
              setAddNewRange({ ...addnewRange, ['discountValue']: e.target.value })
            }} /> */}
          </RACTableCell>
          <RACTableCell className={`${Classes.textCenter}`}>
            <AddRow
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={() => {
                handleAddRow();
              }}
            ></AddRow>
          </RACTableCell>
        </RACTableRow>
        {
          criteriaDetailsForm.range?.map((item: any, index: any) => {
            return (
              <>
                {!(editCriteria) ? (
                  <>
                    <RACTableRow style={{ backgroundColor: "transparent" }}>
                      <RACTableCell>
                        <RACTextbox
                          value={item.startRange}
                          OnChange={(e) => {
                            handleRangeInputs(
                              "startRange",
                              e.target.value,
                              item
                                ? item
                                : {
                                  startRange: "",
                                  endRange: "",
                                  discountValue: "",
                                },
                              index
                            );
                          }}
                        />
                      </RACTableCell>
                      <RACTableCell>
                        <RACTextbox
                          value={item.endRange}
                          OnChange={(e) => {
                            handleRangeInputs(
                              "endRange",
                              e.target.value,
                              item
                                ? item
                                : {
                                  startRange: "",
                                  endRange: "",
                                  discountValue: "",
                                },
                              index
                            );
                          }}
                        />
                      </RACTableCell>
                      <RACTableCell>
                        {Inputfield({fieldName :'', iconRequired : true, isEquation : false}, item.discountValue, (e : any) => {
                          handleRangeInputs(
                            "discountValue",
                            e.target.value,
                            item
                              ? item
                              : {
                                startRange: "",
                                endRange: "",
                                discountValue: "",
                              },
                            index
                          );
                        })}
                        {/*  <RACTextbox
                          value={item.discountValue}
                          Onblur={() => {
                            let INdrange = criteriaDetailsForm.range
                            INdrange[index].discountValue = item.discountValue
                            setCriteriaDetailsForm({ ...criteriaDetailsForm, range: INdrange })
                          }}
                          OnChange={(e) => {
                            handleRangeInputs(
                              "discountValue",
                              e.target.value,
                              item
                                ? item
                                : {
                                  startRange: "",
                                  endRange: "",
                                  discountValue: "",
                                },
                              index
                            );
                          }}
                        /> */}
                      </RACTableCell>
                      <RACTableCell
                        className={`${Classes.textCenter}`}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <DeleteDanger
                          style={{
                            width: "20px",
                            height: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // handleDeleteRow(index);
                            setHandlePopup({
                              isOpen: true,
                              index: index,
                              Content: 'Are you sure you want to delete?'
                            });
                          }}
                        ></DeleteDanger>
                      </RACTableCell>
                    </RACTableRow>
                  </>
                ) : (
                  <RACTableRow style={{ backgroundColor: "transparent" }}>
                    {editRecordsArray?.includes(index) ? (
                      <>
                        <RACTableCell>
                          <RACTextbox
                            value={item?.startRange}
                            OnChange={(e) => {
                              handleRangeInputs(
                                "startRange",
                                e.target.value,
                                item,
                                index
                              );
                            }}
                          />
                        </RACTableCell>
                        <RACTableCell>
                          <RACTextbox
                            value={item?.endRange}
                            OnChange={(e) => {
                              handleRangeInputs(
                                "endRange",
                                e.target.value,
                                item,
                                index
                              );
                            }}
                          />
                        </RACTableCell>
                        <RACTableCell>
                          <RACTextbox
                            value={item?.discountValue}
                            OnChange={(e) => {
                              handleRangeInputs(
                                "discountValue",
                                e.target.value,
                                item,
                                index
                              );
                            }}
                          />
                        </RACTableCell>
                        <RACTableCell
                          className={`${Classes.textCenter}`}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <EditCheck
                            className={`${Classes.mr3}`}
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (
                                item.startDate != "" &&
                                item.endRange != "" &&
                                item.discountValue != ""
                              )
                                handleSaveRow(index);
                            }}
                          ></EditCheck>
                          <EditCross
                            style={{
                              width: "16px",
                              height: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleCancelEdit(index);
                            }}
                          ></EditCross>
                        </RACTableCell>
                      </>
                    ) : (
                      <>
                        <RACTableCell>
                          <Typography
                            variant="caption"
                            className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                          >
                            {item?.startRange}
                          </Typography>
                        </RACTableCell>
                        <RACTableCell>
                          <Typography
                            variant="caption"
                            className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                          >
                            {item?.endRange}
                          </Typography>
                        </RACTableCell>
                        <RACTableCell>
                          <Typography
                            variant="caption"
                            className={`${Classes.fontSemiBold} ${Classes.fs7} ${Classes.textGrey}`}
                          >
                            {tableSymbol(Number(item?.discountValue)?.toFixed(2))}
                          </Typography>
                        </RACTableCell>
                        <RACTableCell
                          className={`${Classes.textCenter}`}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <EditIcon
                            className={`${Classes.mr3}`}
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleEditRow(index);
                            }}
                          ></EditIcon>
                          <DeleteDanger
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              // handleDeleteRow(index);
                              setHandlePopup({
                                isOpen: true,
                                index: index,
                                Content: 'Are you sure you want to delete?'
                              });
                            }}
                          ></DeleteDanger>
                        </RACTableCell>
                      </>
                    )}
                  </RACTableRow>
                )}
              </>
            );
          })
        }
      </>
    );
  };


  function TableRange() {
    return (
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="h4">Table</Typography>
        <Card style={{ height: '70vh' }}>
          <CardContent style={{ padding: '0px !important' }}>
            <Grid style={{ overflowY: 'auto', maxHeight: '68vh' }}>
              <RACTable
                renderTableHead={RangeHead}
                renderTableContent={RangeContent}
                className={Classes.fixTableHeadAgrTrsRec}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  function category() {
    const setInput = (e: any) => {
      const obj = {
        categoryType: criteriaDetailsForm.category.categoryType,
        categorySubType: criteriaDetailsForm.category.categorySubType,
        discountValue: e.target.value

      }
      setCriteriaDetailsForm({ ...criteriaDetailsForm, category: obj })
    }
    return (
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="h4">Category</Typography>
        <Card style={{ height: '70vh' }}>
          <CardContent style={{ padding: '0px !important' }}>
            <Grid container spacing={2}>
              {/* Category type */}
              <Grid item xs={4} md={4} className={`${Classes.mt3} `}>
                <RACSelect
                  isDisabled={
                    criteriaDetailsForm.ruleType == 'CATEGORY'
                  }
                  inputLabel="Category Type"
                  options={dropDownSetter(
                    dropdownValues.categoryType,
                    'categoryType'
                  )}
                  required
                  loading={loader.dropDownLoader}
                  defaultValue={
                    criteriaDetailsForm.category?.categoryType
                      ? criteriaDetailsForm.category.categoryType
                      : ''
                  }
                  onChange={(e) => {
                    const obj = {
                      categoryType: e.target.value,
                      categorySubType: '',
                      discountValue: ''

                    }
                    setCriteriaDetailsForm({ ...criteriaDetailsForm, category: obj })
                    const catSubType = dropdownValues.categorySubType
                      .map((obj: any) => {
                        if (obj.categoryTypeName == e.target.value) {
                          return {
                            label: obj.categorySubTypeName,
                            value: obj.refCode,
                          };
                        }
                      })
                      .filter(Boolean);
                    setCatSubType(catSubType);
                  }}
                ></RACSelect>
              </Grid>
              {/* Category SubType */}
              <Grid item xs={4} md={4} className={`${Classes.mt3} `}>
                <RACSelect
                  isDisabled={!(criteriaDetailsForm?.category?.categoryType && !editCriteria) || (editCriteria)}
                  inputLabel="Category Subtype"
                  options={catSubType}
                  loading={loader.dropDownLoader}
                  defaultValue={criteriaDetailsForm.category?.categorySubType}
                  onChange={(e) => {
                    const obj = {
                      categoryType: criteriaDetailsForm.category.categoryType,
                      categorySubType: e.target.value,
                      discountValue: ''

                    }
                    setCriteriaDetailsForm({ ...criteriaDetailsForm, category: obj })
                  }}
                ></RACSelect>
              </Grid>
              {/* Effective Value */}
              <Grid item xs={4} className={`${Classes.mt3} `}>
                {Inputfield({fieldName :'Effect Value', iconRequired : true, isEquation : false}, criteriaDetailsForm.category?.discountValue, setInput)}
              </Grid>
              {/*               <Grid item xs={4} className={`${Classes.mt3} `}>
                <Typography>Effect Value</Typography>
                <RACTextbox
                  disabled={!(criteriaDetailsForm.category?.categorySubType)}
                  value={
                    criteriaDetailsForm.category?.discountValue
                      ? criteriaDetailsForm.category.discountValue
                      : ''
                  }
                  OnChange={(e) => {
                    const obj = {
                      categoryType: criteriaDetailsForm.category.categoryType,
                      categorySubType: criteriaDetailsForm.category.categorySubType,
                      discountValue: e.target.value

                    }
                    setCriteriaDetailsForm({ ...criteriaDetailsForm, category: obj })
                  }
                  }
                />
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }


  function Equation() {
    return (
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="h4">Equation</Typography>
        <Card style={{ height: '70vh' }}>
          <CardContent style={{ padding: '0px !important' }}>
            <Grid container spacing={2}>
              {/* Equation type */}
              <Grid item xs={4} md={4} className={`${Classes.mt3} `}>
                <RACSelect
                  isDisabled={editCriteria}
                  inputLabel="Equation Type"
                  options={dropDownSetter(
                    dropdownValues.equationType,
                    'equationType'
                  )}
                  required
                  loading={loader.dropDownLoader}
                  defaultValue={criteriaDetailsForm.equation?.equationType}
                  onChange={(e) => {
                    setCriteriaDetailsForm({
                      ...criteriaDetailsForm,
                      ['equation']: {
                        equationType: e.target.value,
                      },
                    });
                    filterEquation(e.target.value)
                  }
                  }
                ></RACSelect>
              </Grid>
              {equationParam?.map((item: any, index) => {
                const setEquationInput = (e: any) => {
                  debugger
                  const equation = criteriaDetailsForm.equation;
                  if (equation.parameters?.length > 0) {
                    equation.parameters[index].discountValue = e?.target?.value ? e.target.value : ''
                  }
                  setCriteriaDetailsForm({ ...criteriaDetailsForm, equation: equation })

                }
                return (
                  <Grid item xs={4} className={`${Classes.mt3} `}>
                    {/* <Typography>{item.parameterName}</Typography> */}
                    { Inputfield({fieldName:(item.parameterName)?.toUpperCase(), iconRequired : false, isEquation : true},item.discountValue,setEquationInput)}
                    {/* <RACTextbox disabled={false} value={item.discountValue}
                      OnChange={}
                    /> */}
                  </Grid>
                );
              })}
              {/* Effective Value */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
  interface FieldInfo{
    fieldName: string,
    iconRequired ?: Boolean,
    isEquation ?: Boolean
  }
  function Inputfield(fieldInfo: FieldInfo, InputValue: any = '', inputChange: any,iconRequired : Boolean = true) {
    const type = criteriaDetailsForm.effectType
    const variant = criteriaDetailsForm.pricingParameterKey
    const currenyRegex = /^\d{1,6}(\.\d{1,3})?$/;
    let IconType = '';
    if (type == 'NUMBER') {
      if (variant == 'SACDAYS' && !(fieldInfo.isEquation))
        IconType = 'DAYS'
      else if (['EPOPERCENT', 'WKTERM'].includes(variant) && !(fieldInfo.isEquation))
        IconType = 'NUMBER'
      else
        IconType = 'AMOUNT'
    }
    else {
      IconType = 'PERCENTAGE'
    }
    const validateInput = async (e: any) => {
      // debugger;
      let canContinue = false;
      const amountregex = /^\d{0,3}(\.\d{0,3})?$/;
      const numberRegex = /^(?:[1-9]\d{0,3}|0)$/;
      if (type == 'NUMBER') {
        if (['WKTERM','SACDAYS'].includes(variant) && !(fieldInfo.isEquation)){
          if((numberRegex.test(e.target.value)) || e.target.value == '')
            canContinue = true;
          }
        else{
          if((amountregex.test(e.target.value)) || e.target.value == ''){
            canContinue = true;
          }
        }

      }
      else {
        const percentRegex = /^(100(\.0+)?|\d{1,2}(\.\d{0,3})?)$/;
        if ((percentRegex.test(e.target.value))|| e.target.value == '') {
          canContinue = true;
        }
        IconType = 'PERCENTAGE'
      }
      if (canContinue)
        await inputChange(e)
      else{
        e.target.value = ''
      inputChange(e)
    }}
    return (<>
      {fieldInfo.fieldName?.trim() ? <Typography>{fieldInfo.fieldName}</Typography> : null}
      <Grid style={{ display: fieldInfo.iconRequired ?'flex' : '' }} >
        {fieldInfo.iconRequired  ?<div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomLeftRadius: '25%',
            borderTopLeftRadius: '25%',
            width: '55px',
            backgroundColor: 'rgb(220,223,228)',
            border: '1px solid lightgrey',
          }}
        >
          {getBusinessValueIcon(IconType)}
        </div> : null}
        <RACTextbox
          maxlength={(IconType == 'NUMBER' && variant != 'EPOPERCENT') || IconType == 'DAYS' ? 3 : 7}
          className={fieldInfo.iconRequired ? sc.businessValue : ''}
          value={InputValue}
          OnChange={validateInput}
        />
      </Grid>
    </>)
  }

  return (
    <>
      <Grid style={{ width: '100%', paddingTop: '0.75rem' }}>
        {breadcrumb()}
        <Grid container spacing={2}>
          {SecondaryCriteria()}
          {criteriaDetailsForm.ruleType == 'TABLE'
            ? TableRange()
            : null}
          {criteriaDetailsForm.ruleType == 'CATEGORY'
            ? category()
            : null}
          {criteriaDetailsForm.ruleType == 'EQUATION'
            ? Equation()
            : null}
        </Grid>
      </Grid>
    </>
  );
}
